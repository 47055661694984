#darkToggle {
    float: right;
    position: fixed !important;
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
  
  }

  #logo {
    background-image: url("../Onlyapp.png");
  }